/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useMemo } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'

export default function Cube({ ...props }) {
  const { size } = useThree()
  const { width } = useMemo(() => {
    const width = size.width;
    return { width }
  }, [size])

  const group = useRef()
  const { nodes, materials } = useGLTF('/cube.glb')
  useFrame(()=>{
    group.current.rotation.y += 0.005;
  })
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh geometry={nodes.Cube.geometry} material={materials.Material} material-color={'coral'} scale={width>800?[0.75,0.75,0.75]:[0.5,1,0.5]}/>
      {/* <mesh geometry={nodes.Retopo_Cube.geometry} material={materials.Material} /> */}
      {/* <mesh geometry={nodes.Retopo_Cube001.geometry} material={materials.Material} /> */}
    </group>
  )
}

useGLTF.preload('/cube.glb')
