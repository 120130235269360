import './App.css';
import Layout from './components/layout'

function App() {
  return (
    <Layout/>
  );
}

export default App;
