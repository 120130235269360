import React, { useEffect, Suspense, useRef, useState, useMemo } from "react"
// import * as THREE from "three"
import { extend, Canvas, useFrame, useThree, createPortal } from "@react-three/fiber"
import { Environment, OrbitControls, softShadows, useFBO, PerspectiveCamera, shaderMaterial } from "@react-three/drei"
import Cube from "./Cube"
import glsl from 'babel-plugin-glsl/macro'
import { EffectsLayer } from "./Effects"

const Three = (props) => {
    const cube = useRef();
    useEffect(() => {
        console.log("three props loaded")
    }, [props.loaded])
    // useFrame(()=>{
    //     cube.current.rotation.x++;
    // })
    return (
        <Canvas 
            // shadows
            // shadowMap
            //#EEEFF0
            camera={{ position: [-4, 0, 0], fov: 50 }}
        >
            <color attach="background" args={["black"]} />
            <fog attach="fog" color="black" near={2} far={8} />
            <ambientLight intensity={0.5} />
            
            <Suspense fallback={null}>
                <Environment files={"SoftLightsStudio2.hdr"} opacity={0} path={"/hdri/"} />
                <Cube loaded={props.loaded}/>
                <EffectsLayer />
                <OrbitControls enableZoom={true} />
            </Suspense>
        </Canvas>
    )
}

export default Three




