import * as THREE from 'three'
import React, { useMemo, useEffect, useRef } from 'react'
import { useThree, useFrame, extend } from '@react-three/fiber'
import { Effects } from '@react-three/drei'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { SavePass } from 'three/examples/jsm/postprocessing/SavePass'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'

extend({ EffectComposer, ShaderPass, SavePass, RenderPass })

const testShader = {
    uniforms: {
      tDiffuse1: { value: null },
      tDiffuse2: { value: null },
      // tDiffuse3: { value: null },
      uTime: { value: null }
    },
    vertexShader: `
      varying vec2 vUv;
      void main() {
        vUv = uv;
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
      }
    `,
    fragmentShader: `
      varying vec2 vUv;
      uniform sampler2D tDiffuse1;
      uniform sampler2D tDiffuse2;
      // uniform sampler2D tDiffuse3;
      uniform float uTime;
      
      void main() {
        // vec4 color = texture2D(tDiffuse1, vUv + vec2(sin(uTime + vUv.x * 1.0) * 0.02, sin(uTime + vUv.y * 1.0) * 0.05));
        // vec4 color = texture2D(tDiffuse1, vUv);
        // vec4 del0 = texture2D(tDiffuse1, vUv);
        // vec4 del1 = texture2D(tDiffuse2, vUv);
        // del1 = vec4(del1.b, del1.g, del1.r, del1.a);
        // vec4 del2 = texture2D(tDiffuse3, vUv);
        // float alpha = min(color.a, del1.a);
       
        // color = mix(color, del1, 0.85);
        // gl_FragColor = vec4(color.rgb, 0.2);
        // gl_FragColor = vec4(vUv, 1., 1.);

        vec4 base = texture2D(tDiffuse1, vUv + vec2(sin(uTime + vUv.x * 25.0) * 0.01, sin(uTime + vUv.y * 25.0) * 0.02));
        // vec4 copy = texture2D(tDiffuse2, vUv);
        vec4 copy = texture2D(tDiffuse2, vec2((vUv.x*1.05)-0.025, (vUv.y*1.05)-0.025));

        // copy.a = (copy.r+copy.g+copy.b);
        copy = copy * 1.45;

        vec4 render = mix(copy, base, 0.3);
        
        gl_FragColor = vec4(render);
      }
    `
  }


// Shader that composites the r,g,b channels of 3 textures, respectively
// const triColorMix = {
//   uniforms: {
//     tDiffuse1: { value: null },
//     tDiffuse2: { value: null },
//     tDiffuse3: { value: null },
//     uTime: { value: null }
//   },
//   vertexShader: `
//     varying vec2 vUv;
//     void main() {
//       vUv = uv;
//       gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1);
//     }
//   `,
//   fragmentShader: `
//     varying vec2 vUv;
//     uniform sampler2D tDiffuse1;
//     uniform sampler2D tDiffuse2;
//     uniform sampler2D tDiffuse3;
//     uniform float uTime;
    
//     void main() {
//       vec3 color = texture2D(tDiffuse1, vUv + vec2(sin(uTime + vUv.x * 5.0) * 0.2, sin(uTime + vUv.y * 5.0) * 0.05)).rgb;

//       vec4 del0 = texture2D(tDiffuse1, vUv);
//       vec4 del1 = texture2D(tDiffuse2, vUv);
//       vec4 del2 = texture2D(tDiffuse3, vUv);
//       float alpha = min(min(del0.a, del1.a), del2.a);
//       gl_FragColor = vec4(del1.rgb, alpha);
//       // gl_FragColor = vec4(color.r,color.g,color.b, alpha);
//     }
//   `
// }

export function EffectsLayer() {
  const savePass = useRef()
  const blendPass = useRef()
  const swap = useRef(false) // Whether to swap the delay buffers
  const { size } = useThree()
  const { rtA } = useMemo(() => {
    const rtA = new THREE.WebGLRenderTarget(size.width, size.height)
    // const rtB = new THREE.WebGLRenderTarget(size.width, size.height)
    return { rtA }
  }, [size])
  // useEffect(() => void composer.current.setSize(size.width, size.height), [size])
  useFrame(() => {
    // Swap render targets and update dependencies
    let delay1 = rtA;
    // let delay1 = swap.current ? rtB : rtA
    // let delay2 = swap.current ? rtA : rtB
    savePass.current.renderTarget = delay1
    blendPass.current.uniforms['tDiffuse2'].value = delay1.texture
    // blendPass.current.uniforms['tDiffuse3'].value = delay2.texture
    // swap.current = !swap.current
  }, 1)
  useFrame(({ clock }) => (blendPass.current.uniforms['uTime'].value = clock.getElapsedTime()))
  //triColorMix
  return (
    <Effects>
      <shaderPass attachArray="passes" ref={blendPass} args={[testShader, 'tDiffuse1']} needsSwap={true} /> 
      <savePass attachArray="passes" ref={savePass} needsSwap={false} />
      <shaderPass attachArray="passes" args={[CopyShader]} />
    </Effects>
  )
}
